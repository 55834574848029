import { FC, useCallback, useState, useMemo } from 'react';

import { IoMdAdd, IoMdInformationCircle, IoMdRemove } from 'react-icons/io';
import { BiSubdirectoryRight } from 'react-icons/bi';

import { useRouter } from 'next/router';

import * as uuid from 'uuid';
import { useToast } from '@chakra-ui/react';

import { routes } from '~/constants/routes';

import { useStore } from '~/hooks/store';
import { useDrawer } from '~/hooks/drawer';
import { useCart } from '~/hooks/cart';

import { ImageContainer } from '~/components/ImageContainer';
import { Button } from '~/components/Button';
import { Badge } from '~/components/Badge';

import { formatCurrency } from '~/utils/formatCurrency';

import { loadProduct } from '~/services/products';

import { ICartProduct } from '~/interfaces/ICartProduct';

import { IProduct } from '~/interfaces/IProduct';
import { ICartAdditional } from '~/interfaces/ICartAdditional';
import {
  Container,
  Product,
  Complements,
  Content,
  NoteTooltip,
} from './styles';

interface IProps {
  product: ICartProduct;
  index: number;
}
export const CartProduct: FC<IProps> = ({ product, index }) => {
  const toast = useToast({
    isClosable: true,
    position: 'top-right',
    status: 'error',
  });

  const { push } = useRouter();
  const { store } = useStore();
  const { closeDrawer } = useDrawer();
  const {
    cart,
    addProductToCart,
    removeProductToCart,
    cleanTheProductInTheCart,
    setEditingProduct,
    setSelectedComboProduct,
  } = useCart();

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleEditingProduct = useCallback(async () => {
    if (product?.items && product.items.length > 0) {
      const productCombo = await loadProduct(product._id);

      setSelectedComboProduct(productCombo, true, product?.items);
    }

    setEditingProduct({
      ...product,
      indexInCart: index,
    });

    closeDrawer();

    push(routes.product(store?.tag, product._id));
  }, [
    closeDrawer,
    index,
    product,
    push,
    setEditingProduct,
    setSelectedComboProduct,
    store,
  ]);

  const handleAddProductToCart = useCallback(
    async (productData: ICartProduct) => {
      if (store.blockOutOfStockProducts) {
        const { stock } = await loadProduct(productData._id);

        if (product.quantity >= stock) {
          toast({
            title: 'Ops, não temos essa quantidade disponível em estoque!',
            description: `Temos apenas ${stock}x ${product.name} em estoque.`,
          });

          return;
        }
      }

      const comboList = (product.items || []).map((item) => {
        return {
          product: { ...(item || {}), comboValue: item.price },
          additionals: item.additionals,
          comboJourneyId: item.comboJourneyId,
          quantity: item.quantity,
          baseQuantity: item.baseQuantity || item.quantity,
        } as {
          product: IProduct;
          quantity: number;
          baseQuantity: number;
          additionals: ICartAdditional[];
          comboJourneyId: string;
        };
      });

      addProductToCart(productData, comboList);
    },
    [
      addProductToCart,
      product.name,
      product.quantity,
      store.blockOutOfStockProducts,
      toast,
    ]
  );

  const productFinalPrice = useMemo(() => {
    if (!cart || !cart.length) {
      return 0;
    }

    let totalPrice = 0;
    const cartProduct = cart.find((item) => item._id === product._id && item.price === product.price);
    totalPrice += cartProduct.price * cartProduct.quantity;

    let additionalsTotalPrice = 0;
    let totalPriceCombo = 0;

    cartProduct.additionals &&
      cartProduct.additionals.forEach((additional) => {
        additional.options.forEach((option) => {
          additionalsTotalPrice += option.price * option.quantity;
        });
      });

    if (cartProduct?.isComboWithinValue) {
      totalPriceCombo = (cartProduct?.items || []).reduce((acc, nextItem) => {
        let additionalsComboTotalPrice = 0;

        nextItem.additionals &&
          nextItem.additionals.forEach((additional) => {
            additional.options.forEach((option) => {
              additionalsComboTotalPrice += option.price * option.quantity;
            });
          });

        return acc + nextItem.price + additionalsComboTotalPrice;
      }, 0);

      totalPrice +=
        totalPriceCombo * cartProduct.quantity -
        cartProduct.price * cartProduct.quantity;
    }

    totalPrice += additionalsTotalPrice * cartProduct.quantity;
    return totalPrice;
  }, [cart, product]);

  return (
    <Container>
      <Product storeTheme={store?.theme}>
        <ImageContainer pictures={[product.pictures[0]]} size="sm" />

        <Content>
          <div className="description">
            <div className="title">
              <h2>{product.name}</h2>

              {product.note && (
                <NoteTooltip
                  onMouseEnter={() => setTooltipVisible(true)}
                  onMouseLeave={() => setTooltipVisible(false)}
                >
                  <IoMdInformationCircle />

                  {tooltipVisible && (
                    <div className="tooltip-container">
                      <p>{product.note}</p>
                    </div>
                  )}
                </NoteTooltip>
              )}
            </div>

            <Badge size="sm" style={{ marginRight: '9px' }}>
              {product.quantity}x
            </Badge>
            <Badge size="sm">{formatCurrency(productFinalPrice)}</Badge>
          </div>

          <div className="actions">
            <Button
              size="sm"
              backgroundColor="secondary"
              textColor="onSecondary"
              onClick={handleEditingProduct}
            >
              Editar
            </Button>
            <Button
              size="sm"
              backgroundColor="danger"
              textColor="onDanger"
              onClick={() => cleanTheProductInTheCart(product)}
            >
              Excluir
            </Button>
          </div>
        </Content>

        <div className="remove-add-buttons">
          <button
            type="button"
            onClick={() =>
              handleAddProductToCart({
                tempId: uuid.v4(),
                _id: product._id,
                description: product.description,
                dimensions: product.dimensions,
                name: product.name,
                pictures: product.pictures,
                price: product.price,
                promotionalPrice: product.promotionalPrice,
                quantity: 1,
                additionals: product.additionals,
                note: product.note,
              })
            }
          >
            <IoMdAdd />
          </button>
          <button type="button" onClick={() => removeProductToCart(product)}>
            <IoMdRemove />
          </button>
        </div>
      </Product>

      {product.additionals && (
        <Complements>
          {product.additionals.map((additional) =>
            additional.options?.map((option) => (
              <>
                {additional.removable ? <h3>Remover</h3> : <h3>Adicionar</h3>}
                <li key={`${product._id}-${option._id}`}>
                  <BiSubdirectoryRight />
                  {option.quantity}x {option.name} -{' '}
                  {formatCurrency(option.price || 0)}
                </li>
              </>
            ))
          )}
        </Complements>
      )}

      {product.items && (
        <Complements>
          {product.items.map((comboItem) => (
            <>
              <li key={comboItem._id}>
                <BiSubdirectoryRight />
                <b style={{ paddingRight: 4 }}>
                  {`${comboItem.quantity}x ${comboItem.name?.toUpperCase()} -`}
                </b>

                {formatCurrency((comboItem.price || 0) * comboItem.quantity)}
              </li>
              {comboItem.additionals && (
                <Complements style={{ paddingLeft: 20 }}>
                  {comboItem.additionals.map((additional) =>
                    additional.options?.map((option) => (
                      <>
                        {additional.removable ? (
                          <h3>Remover</h3>
                        ) : (
                          <h3>Adicionar</h3>
                        )}
                        <li key={`${comboItem._id}-${option._id}`}>
                          <BiSubdirectoryRight />
                          {option.quantity}x {option.name} -{' '}
                          {formatCurrency(option.price || 0)}
                        </li>
                      </>
                    ))
                  )}
                </Complements>
              )}
            </>
          ))}
        </Complements>
      )}
    </Container>
  );
};
